import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC1xjhncyoNXWAjEUOoICnbupq1qQg78gI",
  authDomain: "reactnativedemoandroid-bc3e2.firebaseapp.com",
  projectId: "reactnativedemoandroid-bc3e2",
  storageBucket: "reactnativedemoandroid-bc3e2.appspot.com",
  messagingSenderId: "299977864506",
  appId: "1:299977864506:web:ebd87b421265427a740c03",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
