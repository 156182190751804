// export const APP_ID = '228147d3fbc6cdb4';
// export const APP_ID = '20889759074dd9cd';
export const APP_ID = '834c329daccea';
export const REGION = 'us';
// export const AUTH_KEY = '65fdf74d80a3ac10e03c2edc8f7a85af14757a65';
// export const AUTH_KEY = '7940ec021015805ed0741f5f53a69b377515cac7';
export const AUTH_KEY = 'ef395e58ead6406ec82d21b2b1f89d0dc825a848';
export const WEB_APP_HOME = 'https://cometchat.pages.dev/';
export const WEB_APP_DYNAMIC_LINK = 'https://cometchat.page.link/?link=https://cometchat.pages.dev/__ID__&apn=com.reactnativecallingandroid';
export const WIDGET_ID = '70470a88-7f9e-4786-9dec-af41c3e6de11';
