import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const CssTextField = styled(TextField)({
  "& label": {
    color: "var(--secondary-text)",
  },
  "& label.Mui-focused": {
    color: "var(--primary-text)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "var(--primary)",
    color: "var(--primary-text)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--primary)",
      color: "var(--primary-text)",
    },
    "&:hover fieldset": {
      borderColor: "var(--primary)",
      color: "var(--primary-text)",
      borderWidth: "2px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--primary)",
      color: "var(--primary-text)",
    },
  },
  input: {
    color: "var(--primary-text)",
  },
});

export default CssTextField;
